import request from "@/utils/request";

const tagApi = {
  GetTagList: "/label/list",
  AddTag: "/label/add",
  UpdateTag: "/label/edit",
  DeleteTag: "/label/del",
  GetProductsByLabel: "/label/products", // 通过label获取数据
  HasRelateProduct:'/label/has-relate-product',
  SearchTag:'/label/search',
  GetLabelRelationship:'/label-relation/relation-graph',

  GetLabelFilterList: "/label-filter/list",
  GetFilterColumnList: "/label-filter/column-list",
  AddLabelFilter: "/label-filter/add",
  UpdateLabelFilter: "/label-filter/edit",
  DeleteLabelFilter: "/label-filter/del",
  DeleteLabelFilterByGroup: "/label-filter/del-group",

  // 子标签
  GetChildLabels: "/label/child-labels",
  AddChildLabel: "/label-relation/add",
  DeleteChildLabel: "/label-relation/del",
  ActiveChildLabel: "label/take-effect",
  DeactiveChildLabel: "label/lose-effectiveness",
};

export function getTagList() {
  return request({
    url: tagApi.GetTagList,
    method: "get",
  });
}

export function addTag(parameter) {
  return request({
    url: tagApi.AddTag,
    method: "post",
    data: parameter,
  });
}

export function updateTag(parameter) {
  return request({
    url: tagApi.UpdateTag,
    method: "post",
    data: parameter,
  });
}

export function deleteTag(parameter) {
  return request({
    url: tagApi.DeleteTag + `/${parameter.id}`,
    method: "post",
  });
}

export function getProductsByLabel(parameter) {
  return request({
    url: tagApi.GetProductsByLabel,
    method: "get",
    params: parameter
  });
}

export function getFilterColumnList() {
  return request({
    url: tagApi.GetFilterColumnList,
    method: "get",
  });
}

export function getLabelFilterList(parameter) {
  return request({
    url: tagApi.GetLabelFilterList,
    method: "get",
    params: parameter,
  });
}

export function addLabelFilter(parameter) {
  return request({
    url: tagApi.AddLabelFilter,
    method: "post",
    data: parameter,
  });
}

export function updateLabelFilter(parameter) {
  return request({
    url: tagApi.UpdateLabelFilter,
    method: "post",
    data: parameter,
  });
}

export function deleteLabelFilter(parameter) {
  return request({
    url: tagApi.DeleteLabelFilter + `/${parameter.id}`,
    method: "post",
  });
}

export function deleteLabelFilterByGroup(parameter) {
  return request({
    url: tagApi.DeleteLabelFilterByGroup + `/${parameter.id}`,
    method: "post",
  });
}

export function getChildLabels(parameter) {
  return request({
    url: tagApi.GetChildLabels,
    method: "get",
    params: parameter
  });
}

export function addChildLabel(parameter) {
  return request({
    url: tagApi.AddChildLabel,
    method: "post",
    data: parameter
  });
}

export function deleteChildLabel(parameter) {
  return request({
    url: tagApi.DeleteChildLabel,
    method: "post",
    data: parameter
  });
}

export function activeChildLabel(parameter) {
  return request({
    url: tagApi.ActiveChildLabel + `/${parameter.labelId}`,
    method: "post",
  });
}

export function deactiveChildLabel(parameter) {
  return request({
    url: tagApi.DeactiveChildLabel + `/${parameter.labelId}`,
    method: "post",
  });
}

export function hasRelateProduct(parameter) {
  return request({
    url:tagApi.HasRelateProduct,
    method:'get',
    params:parameter
  })
}

export function searchTag(parameter) {
  return request({
    url:tagApi.SearchTag,
    method:'get',
    params:parameter
  })
}

export function  getLabelRelationship(parameter) {
  return request({
    url:tagApi.GetLabelRelationship + `/${parameter.labelId}`,
    method:'get'
  })
}
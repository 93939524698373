<template>
  <div id="App">
    <div style="padding-left: 20px">
      <a-button
        v-if="hasAction('add-label')"
        type="primary"
        icon="plus"
        @click="handleEdit()"
        style="margin-bottom: 20px"
      >
        新增标签
      </a-button>
      <a-table
        :columns="columns"
        :dataSource="data"
        :loading="loading"
        rowKey="id"
        :pagination="{ pageSize: 10 }"
      > 
      <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            id="searchInput"
            placeholder="请输入基金名称"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            筛选
          </a-button>
          <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"> 重置 </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? 'yellow' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{
                fragment
              }}</mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <template slot="dataManage" slot-scope="text, record">
          <div>
            <a @click="goto(record)">编辑</a>
            <a-divider type="vertical" v-if="hasAction('delete-label')" />
            <a-popconfirm
              v-if="hasAction('delete-label') && record.hasRelateProduct"
              title="该标签尚有关联项目，不能删除标签"
              ok-text="确定"
              cancel-text="取消"
            >
              <a @click="check(record)">删除</a>
            </a-popconfirm>
            <a-popconfirm
              v-if="hasAction('delete-label') && !record.hasRelateProduct"
              title="确定移除该标签吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="handleDelete(record)"
            >
              <a @click="check(record)">删除</a>
            </a-popconfirm>
            <a-divider
              type="vertical"
              v-if="hasAction('delete-label') && hasAction('rename-label')"
            />
            <a v-if="hasAction('rename-label')" @click="handleEdit(record)"
              >重命名</a
            >
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import TagForm from "./components/TagForm";
import { getTagList, deleteTag, hasRelateProduct } from "@/api/tag";
import Vue from 'vue';

const columns = [
  {
    title: "标签名称",
    dataIndex:'name',
    width: '25vw',
    scopedSlots: {
      customRender: 'customRender',
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
    onFilter: (value, record) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          document.getElementById('searchInput').focus()
        }, 0)
      }
    },
    align: 'center',
  },
  {
    title: "管理",
    dataIndex: "finance-manage",
    scopedSlots: {
      customRender: "dataManage",
    },
    align: 'center',
  },
];

export default {
  data() {
    return {
      loading: false,
      data: [],
      columns,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      finance: {
        dataSource: [],
        info: {
          round: "",
          investorName: "",
          moneyNum: "",
          date: "",
          realTime: "",
          financeAdvisor: "",
        },
      },
    };
  },
  created() {
    this.companyId = this.$route.params.companyId;
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getTagList().then((res) => {
        this.data = res.data;
        this.data.forEach((item) => {
          Vue.set(item,'hasRelateProduct',false)
        })
        this.loading = false;
      });
      
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    handleEdit(record) {
      this.$dialog(
        TagForm,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "新增标签",
          width: 450,
          height: 300,
          centered: true,
          maskClosable: false,
        }
      );
    },
    check(record) {
      hasRelateProduct({labelId:record.id}).then((res) => {
        if(res.code === 0) {
          record.hasRelateProduct = res.data
        }
      })
    },
    handleDelete(record) {
      deleteTag(record).then(() => {
        this.loadData();
        this.$message.success("删除成功！");
      });
    },
    goto(record) {
      this.$router.push({
        path: "/system/tag/list/project",
        query: {
          labelId: record.id,
          labelName: record.name,
        },
      });
    },
  },
};
</script>

<style  scoped>
#App .content-title {
    line-height: 40px;
    margin-left: -20px;
  }

.ant-table-thead tr th .ant-table-filter-icon {
  right: unset;
  font-size: 16px;
}
.ant-table-thead tr th .ant-table-filter-icon >>> svg {
  margin-top: 0;
  transform: translateY(-50%);
}
</style>

<template>
  <a-form :form="form">
    <a-form-item label="标签名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入标签名称"
        v-decorator="['name', { initialValue: record ? record.name : '' }]"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { addTag, updateTag } from "@/api/tag";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            if (this.record && this.record.id) {
              // 更新
              let formData = {
                id: this.record.id,
                name: values.name,
              };
              updateTag(formData).then(() => {
                this.$message.success("更新成功");
                resolve(true);
              });
            } else {
              // 创建
              let formData = {
                name: values.name,
              };
              addTag(formData).then(() => {
                this.$message.success("添加成功！");
                resolve(true);
              });
            }
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>
